import { create } from "zustand";

interface DashboardFilterStore {
  filters: Record<string, any>;
  setFilters: (filters: Record<string, any>) => void;
}

export const useDashboardFilterStore = create<DashboardFilterStore>((set) => ({
  filters: {},
  setFilters: (filters: Record<string, any>) => set({ filters }),
}));
