import {
  map,
  last,
  groupBy,
  find,
  compact,
  mean,
  transform,
  orderBy,
  sum,
} from "lodash-es";
import { formatISO, differenceInDays } from "date-fns";
import {
  Leak,
  Timer,
  HighRiseBuilding,
  ShowerHead,
  Sensor,
} from "@smartrent/icons";
import { Pie } from "react-chartjs-2";

import Helpers from "@/lib/helpers";
import Graph from "@/common/Graph";
import { useDocumentTitle } from "@/hooks/use-document-title";

export const IoTDashboardPage = () => {
  useDocumentTitle("Insights - IoT Dashboard");

  return (
    <Graph
      path="/reports/automated-work-orders"
      dataKey="automatedWos"
      renderProps={(raw) => {
        const leakLocations = orderBy(
          map(
            groupBy(raw.leakLocations, (r) => {
              if (
                r.device_name.toLowerCase().includes("kitchen") ||
                r.device_name.toLowerCase().includes("fridge")
              ) {
                return "Kitchen";
              } else if (
                r.device_name.toLowerCase().includes("a.c") ||
                r.device_name.toLowerCase().includes("a/c") ||
                r.device_name.toLowerCase().includes("handler") ||
                r.device_name.toLowerCase().includes("ac") // also handles hvac
              ) {
                return "A/C";
              } else if (
                r.device_name.toLowerCase().includes("heater") ||
                r.device_name.toLowerCase().includes("w/h") ||
                r.device_name.toLowerCase().includes("water tank") ||
                r.device_name.toLowerCase().includes("boiler")
              ) {
                return "Water Heater";
              } else if (
                r.device_name.toLowerCase().includes("laundry") ||
                r.device_name.toLowerCase().includes("washer")
              ) {
                return "Laundry";
              } else if (r.device_name.toLowerCase().includes("bath")) {
                return "Bath";
              } else if (r.device_name.toLowerCase().includes("sink")) {
                return "Sink";
              } else {
                return "Unknown";
              }
            }),
            (records, location) => {
              return {
                count: sum(
                  map(records, (r) => {
                    return Number(r.count);
                  })
                ),
                location,
              };
            }
          ),
          "count",
          "desc"
        );

        const mungedData = compact(
          map(
            raw.propertyLeakTimeData,
            ({
              group_name,
              count,
              time_data,
              floors_that_could_have_been_affected,
            }) => {
              if (Number(count) < 1) {
                return null;
              }

              const firstLeakSensorInstalledRecord = find(
                raw.leakSensorsFirstInstalled,
                { group_name }
              );

              const transformed_time_data = [
                firstLeakSensorInstalledRecord?.first_leak_sensor_install_date,
                ...time_data,
                formatISO(new Date()),
              ];

              const time_between_dates = compact(
                map(
                  transformed_time_data,
                  (date: string, idx: number): number | null => {
                    if (!(date && transformed_time_data[idx - 1])) {
                      return null;
                    }
                    return differenceInDays(
                      new Date(date),
                      new Date(transformed_time_data[idx - 1])
                    );
                  }
                )
              );
              const avgTimeBetweenDates = time_between_dates.length
                ? mean(time_between_dates)
                : 0;

              const daysUntilFirstLeak =
                firstLeakSensorInstalledRecord?.first_leak_sensor_install_date
                  ? differenceInDays(
                      new Date(time_data[0]),
                      new Date(
                        firstLeakSensorInstalledRecord?.first_leak_sensor_install_date
                      )
                    )
                  : 0;

              const daysSinceLastLeak = differenceInDays(
                new Date(),
                new Date(last(time_data))
              );

              return {
                group_name,
                count,
                time_data,
                floors_that_could_have_been_affected,
                avgTimeBetweenDates,
                firstLeakSensorInstalledRecord,
                daysUntilFirstLeak,
                daysSinceLastLeak,
              };
            }
          )
        );

        const cumulativeDataPoints = transform(
          mungedData,
          (
            acc,
            {
              group_name,
              count,
              time_data,
              floors_that_could_have_been_affected,
            }
          ) => {
            acc.leaks += Number(count);
            acc.properties += 1;
            acc.floors += Number(floors_that_could_have_been_affected);

            return acc;
          },
          { leaks: 0, properties: 0, floors: 0, daysUntilFirstLeak: 0 }
        );

        cumulativeDataPoints.daysUntilFirstLeak =
          mungedData.length > 0
            ? mean(
                map(mungedData, ({ daysUntilFirstLeak }) => daysUntilFirstLeak)
              )
            : 0;

        const propertyData = compact(
          map(
            mungedData,
            ({
              group_name,
              count,
              floors_that_could_have_been_affected,
              avgTimeBetweenDates,
              daysUntilFirstLeak,
              daysSinceLastLeak,
            }) => {
              return (
                <div
                  key={group_name}
                  className="u-mbottom16 u-mright16"
                  style={{ width: "350px" }}
                >
                  <h4 className="h4">{group_name}</h4>
                  <div>
                    <span className="u-text-gray-600">Verified leaks: </span>
                    {count}
                  </div>

                  <div>
                    <span className="u-text-gray-600">
                      First leak detected after:{" "}
                    </span>
                    {daysUntilFirstLeak} days
                  </div>

                  <div>
                    <span className="u-text-gray-600">
                      Days between detected leaks (avg):{" "}
                    </span>
                    {Math.round(avgTimeBetweenDates)}{" "}
                  </div>

                  <div>
                    <span className="u-text-gray-600">
                      Days since last leak:
                    </span>{" "}
                    {daysSinceLastLeak}
                  </div>

                  <div>
                    <span className="u-text-gray-600">
                      Cumulative # of floors:
                    </span>{" "}
                    {floors_that_could_have_been_affected}
                  </div>
                </div>
              );
            }
          )
        );

        const pieData = {
          labels: map(
            leakLocations,
            ({ location, count }) => `${location} (${count})`
          ),
          datasets: [
            {
              data: map(leakLocations, ({ count }) => count),
              backgroundColor: Helpers.getGraphColors(),
              hoverBackgroundColor: Helpers.getGraphColors(),
            },
          ],
        };
        // @ts-ignore
        const pie = <Pie responsive={true} height={110} data={pieData} />;

        return (
          <div>
            <div className="u-flex u-flex-wrap u-flex-justify-between u-mbottom16">
              <div className="u-mbottom16 u-font24 u-line60 u-flex-shrink1 u-flex-grow1">
                <div className="u-flex u-flex-align-items-center">
                  <Sensor size={30} color="#333" />
                  <span className="u-mleft4 u-sans-bold u-mright4">
                    {Math.round(
                      (cumulativeDataPoints.properties /
                        Number(raw.propertyCount)) *
                        100
                    ) || 0}
                    %
                  </span>{" "}
                  of properties had leaks detected
                </div>
                <div className="u-flex u-flex-align-items-center">
                  <ShowerHead size={30} color="#333" />
                  <span className="u-mleft4 u-sans-bold u-text-symbolic-success  u-mright4">
                    {cumulativeDataPoints.leaks.toLocaleString()}
                  </span>{" "}
                  verified leaks detected
                </div>
                <div className="u-flex u-flex-align-items-center">
                  <Timer size={28} color="#333" />
                  <span className="u-mleft4 u-sans-bold u-text-symbolic-success  u-mright4">
                    {Math.round(cumulativeDataPoints.daysUntilFirstLeak)} days
                  </span>{" "}
                  until first leak detected (avg)
                </div>
                <div className="u-flex u-flex-align-items-center">
                  <HighRiseBuilding size={28} color="#333" />
                  <span className="u-mleft4 u-sans-bold u-text-symbolic-error u-mright4">
                    {cumulativeDataPoints.floors.toLocaleString()}
                  </span>{" "}
                  potentially affected floors if uncaught
                </div>
                <div className="u-flex u-flex-align-items-center">
                  <Leak size={28} color="#333" />
                  <span className="u-mleft4 u-sans-bold u-mright4">
                    {(
                      Number(raw.propertyCount) / cumulativeDataPoints.leaks ||
                      0
                    ).toFixed(2)}
                  </span>{" "}
                  average leaks per property
                </div>
              </div>

              <div
                className="u-flex-grow1 u-flex-shrink1"
                style={{ height: "500px" }}
              >
                {pie}
              </div>
            </div>

            <hr />

            <div className="u-flex u-flex-wrap">{propertyData}</div>
          </div>
        );
      }}
    />
  );
};
