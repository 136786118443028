import { View } from "react-native";

import { Card, useTheme } from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { FullScreenLoader, Loader } from "@/common/Loader";

import { useDocumentTitle } from "@/hooks/use-document-title";

import { useApi } from "../../../lib/hooks";

import IdentityStats from "../../tours-dashboard/IdentityStats";
import PropertiesMap from "../../tours-dashboard/PropertiesMap";
import ToursPerDay from "../../tours-dashboard/ToursPerDay";
import UpcomingTours from "../../tours-dashboard/UpcomingTours";
import VerificationsByDay from "../../tours-dashboard/VerificationsByDay";
import AvgTimeToFinalize from "../../tours-dashboard/AvgTimeToFinalize";
import TourFunnel from "../../tours-dashboard/TourFunnel";
import PopularTourTimes from "../../tours-dashboard/PopularTourTimes";
import ImageErrorStats from "../../tours-dashboard/ImageErrorStats";

export const ToursDashboardPage = () => {
  useDocumentTitle("Insights - Tours Dashboard");

  const { colors } = useTheme();

  const { permissions } = useUser();

  const { response } = useApi({
    url: `/groups-with-tours`,
    trigger: [],
  });

  const { response: accessCodeViewedTourCount } = useApi({
    url: `/tours/access-code-viewed-tour-count`,
    trigger: [],
  });

  const { response: scheduledToursCount } = useApi({
    url: `/tours/scheduled-tours-count`,
    trigger: [],
  });

  const { response: scheduledNowToursCount } = useApi({
    url: `/tours/scheduled-now-tours-count`,
    trigger: [],
  });

  const { response: averageMinutesToFinalize } = useApi({
    url: `/tours/average-time-to-finalize`,
    trigger: [],
  });

  const { response: activeUnitCount } = useApi({
    url: `/units/active-tour-units-count`,
    trigger: [],
  });

  const { response: prospectCount } = useApi({
    url: `/prospects/prospects-count`,
    trigger: [],
  });

  const { response: toursEnabledGroupsCount } = useApi({
    url: `/tours/tours-enabled-groups-count`,
    trigger: [],
  });

  const { response: toursEnabledOrganizationsCount } = useApi({
    url: `/tours/tours-enabled-organizations-count`,
    trigger: [],
  });

  const { response: averageToursPerUnitPerMonth } = useApi({
    url: `tours/avg-tours-per-unit-per-month`,
    trigger: [],
  });

  if (!response?.data) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <ToursPerDay />
      <div className="u-flex u-flex-wrap">
        <div className="u-flex-grow3">
          <VerificationsByDay />
        </div>
        <div className="u-flex-grow1" style={{ maxWidth: "418px" }}>
          <IdentityStats />
        </div>
      </div>
      <View>
        <TourFunnel />
        <PopularTourTimes />
        {permissions.manual_verifications && <ImageErrorStats />}
      </View>

      <PropertiesMap
        groups={response.data.tourGroupsWithCounts}
        mapboxApiKey={response.data.mapboxApiKey}
      />

      <View
        style={{
          flexWrap: "wrap",
          justifyContent: "center",
          flexDirection: "row",
          marginTop: 16,
        }}
      >
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Organizations Live</h4>
            <div
              style={{
                ...styles.largeMetricText,
                color: colors.primary,
                marginTop: 40,
              }}
            >
              {toursEnabledOrganizationsCount?.data?.toursEnabledOrganizationsCount?.toLocaleString() || (
                <Loader />
              )}
            </div>
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Properties Live</h4>
            <div
              style={{
                ...styles.largeMetricText,
                color: colors.primary,
                marginTop: 40,
              }}
            >
              {toursEnabledGroupsCount?.data?.toursEnabledGroupsCount?.toLocaleString() || (
                <Loader />
              )}
            </div>
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Active Units</h4>
            <div
              style={{
                ...styles.largeMetricText,
                color: colors.primary,
                marginTop: 40,
              }}
            >
              {activeUnitCount?.data?.unitCount?.toLocaleString() || <Loader />}
            </div>
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Tours (Finalized)</h4>
            <div
              style={{
                ...styles.largeMetricText,
                color: colors.primary,
                marginTop: 40,
              }}
            >
              {scheduledToursCount?.data?.tourCount?.toLocaleString() || (
                <Loader />
              )}
            </div>
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Total Prospects</h4>
            <div
              style={{
                ...styles.largeMetricText,
                color: colors.primary,
                marginTop: 40,
              }}
            >
              {prospectCount?.data?.prospectCount?.toLocaleString() || (
                <Loader />
              )}
            </div>
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Tours (Code Viewed)</h4>
            <div
              style={{
                ...styles.largeMetricText,
                color: colors.primary,
                marginTop: 40,
              }}
            >
              {accessCodeViewedTourCount?.data?.tourCount?.toLocaleString() || (
                <Loader />
              )}
            </div>
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>Tour Now</h4>
            {scheduledToursCount && scheduledNowToursCount && (
              <div style={{ marginTop: 40 }}>
                <div
                  style={{
                    ...styles.largeMetricText,
                    color: colors.primary,
                  }}
                >
                  {Number(scheduledToursCount?.data?.tourCount) > 0
                    ? Math.round(
                        (Number(scheduledNowToursCount?.data?.tourCount) /
                          Number(scheduledToursCount?.data?.tourCount)) *
                          100
                      )
                    : 0}
                </div>
                <div style={styles.smallMetricText}>PERCENT</div>
              </div>
            )}
          </Card>
        </div>
        <div className="u-m8">
          <Card style={styles.cardSize}>
            <h4 style={styles.metricHeading}>
              Tours Scheduled Per Unit Per Month
            </h4>
            {scheduledToursCount && activeUnitCount && (
              <div style={{ marginTop: 40 }}>
                <div
                  style={{
                    ...styles.largeMetricText,
                    color: colors.primary,
                  }}
                >
                  {averageToursPerUnitPerMonth?.data
                    ?.averageToursPerUnitPerMonth.tours_per_unit_per_month
                    ? Math.round(
                        averageToursPerUnitPerMonth?.data
                          ?.averageToursPerUnitPerMonth
                          .tours_per_unit_per_month * 100
                      ) / 100
                    : 0}
                </div>
              </div>
            )}
          </Card>
        </div>
      </View>

      <div className="u-mtop16 u-mbottom16 u-mleft8 u-mright8">
        <AvgTimeToFinalize
          averages={averageMinutesToFinalize?.data?.minutesToFinalize}
        />
      </div>

      <UpcomingTours />
    </>
  );
};

const styles: any = {
  cardSize: { height: 180, width: 270 },
  metricHeading: {
    color: "#878787",
    fontSize: 16,
    fontWeight: 900,
    textAlign: "center",
  },
  largeMetricText: {
    fontSize: 64,
    fontWeight: "bold",
    marginBottom: 18,
    textAlign: "center",
  },
  smallMetricText: {
    color: "#232323",
    display: "block",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
};
