import { DatePickerField } from "@smartrent/forms";
import { HStack } from "@smartrent/ui";

import { OrganizationMultiSelect } from "@/common/filters/OrganizationMultiSelect";
import { GroupMultiSelect } from "@/common/filters/GroupMultiSelect";
import { OrganizationProps } from "@/types";

import { useDashboardFilterStore } from "./dashboards-filter-state";

export const DashboardsFilters = () => {
  const { filters, setFilters } = useDashboardFilterStore();

  const onOrganizationChange = (
    organizationIds: Record<string, OrganizationProps>[]
  ) => {
    setFilters({ ...filters, organizationIds });
  };

  const onGroupChange = (groupIds: Record<string, any>[]) => {
    setFilters({ ...filters, groupIds });
  };

  return (
    <HStack spacing={4} flexChildren={true}>
      <OrganizationMultiSelect onChange={onOrganizationChange} />

      <GroupMultiSelect
        onChange={onGroupChange}
        filters={{
          organization_ids:
            filters.organizationIds?.map((o: OrganizationProps) => o.id) || [],
        }}
      />

      <DatePickerField
        // StartAdornment={Calendar}
        value={filters.startDate || undefined}
        onChangeDate={(date) => setFilters({ ...filters, startDate: date })}
        label="Select Start Date"
        maxDate={filters.endDate || undefined}
      />

      <DatePickerField
        // StartAdornment={Calendar}
        value={filters.endDate || undefined}
        onChangeDate={(date) => setFilters({ ...filters, endDate: date })}
        label="Select End Date"
        minDate={filters.startDate || undefined}
      />
    </HStack>
  );
};
