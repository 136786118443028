import { useEffect, useRef } from "react";
import {
  ContentOptions,
  createEmbeddingContext,
  EmbeddingContext,
  FrameOptions,
} from "amazon-quicksight-embedding-sdk";

import { fetchQuickSightDashboardEmbedUrl } from "@/api/quicksight-dashboards";

interface QuickSightDashboardProps {
  id: "client-dashboard"; // | 'iot' | 'tours';
}

export const QuickSightDashboard = ({ id }: QuickSightDashboardProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let embeddingContext: EmbeddingContext;
    (async () => {
      embeddingContext = await createEmbeddingContext();
      const embedUrl = await fetchQuickSightDashboardEmbedUrl(id);
      console.log("embedUrl", embedUrl);

      if (!embedUrl) {
        console.error("Could not fetch embed url");
        return;
      }

      if (!containerRef.current) {
        console.error("Container not found");
        return;
      }

      const frameOptions: FrameOptions = {
        url: embedUrl,
        container: containerRef.current.id,
        height: containerRef.current.clientHeight + "px",
        width: containerRef.current.clientWidth + "px",
        onChange: (changeEvent, metadata) => {
          switch (changeEvent.eventName) {
            case "FRAME_MOUNTED": {
              console.log("Do something when the experience frame is mounted.");
              break;
            }
            case "FRAME_LOADED": {
              console.log("Do something when the experience frame is loaded.");
              break;
            }
          }
        },
      };

      const contentOptions: ContentOptions = {
        parameters: [
          {
            Name: "organizations",
            Values: [],
          },
          {
            Name: "properties",
            Values: [],
          },
          {
            Name: "start_date",
            Values: [],
          },
          {
            Name: "end_date",
            Values: [],
          },
        ],
        locale: "en-US",
        sheetOptions: {
          initialSheetId: "<YOUR_SHEETID>",
          singleSheet: false,
          emitSizeChangedEventOnSheetChange: false,
        },
        toolbarOptions: {
          export: false,
          undoRedo: false,
          reset: false,
        },
        attributionOptions: {
          overlayContent: false,
        },
        onMessage: async (messageEvent, experienceMetadata) => {
          switch (messageEvent.eventName) {
            case "CONTENT_LOADED": {
              console.log(
                "All visuals are loaded. The title of the document:",
                messageEvent.message?.title
              );
              break;
            }
            case "ERROR_OCCURRED": {
              console.log(
                "Error occurred while rendering the experience. Error code:",
                messageEvent.message?.errorCode
              );
              break;
            }
            case "PARAMETERS_CHANGED": {
              console.log(
                "Parameters changed. Changed parameters:",
                messageEvent.message?.changedParameters
              );
              break;
            }
            case "SELECTED_SHEET_CHANGED": {
              console.log(
                "Selected sheet changed. Selected sheet:",
                messageEvent.message?.selectedSheet
              );
              break;
            }
            case "SIZE_CHANGED": {
              console.log(
                "Size changed. New dimensions:",
                messageEvent.message
              );
              break;
            }
            case "MODAL_OPENED": {
              window.scrollTo({
                top: 0, // iframe top position
              });
              break;
            }
          }
        },
      };
      const embeddedDashboardExperience = await embeddingContext.embedDashboard(
        frameOptions,
        contentOptions
      );
      console.log("embeddedDashboardExperience", embeddedDashboardExperience);

      // const selectCountryElement = document.getElementById('country');
      // selectCountryElement.addEventListener('change', (event) => {
      //     embeddedDashboardExperience.setParameters([
      //         {
      //             Name: 'country',
      //             Values: event.target.value
      //         }
      //     ]);
      // });
    })();

    return () => {
      // clean up if you can
    };
  }, [id]);

  return (
    <div>
      <div ref={containerRef} />
    </div>
  );
};
